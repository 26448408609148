import { AppLayout } from '@app/components/app-layout'
import { MarketingDrawer, SignOnForm } from '@app/components/general'
import { MarketingShortTitle } from '@app/components/marketing'
import { metaSetter } from '@app/utils'
import loadNamespaces from 'next-translate/loadNamespaces'
import type { GetStaticProps } from 'next'
import { I18nDictionary } from 'next-translate'

export const getStaticProps: GetStaticProps<{
  __lang: string
  __namespaces?: Record<string, I18nDictionary>
}> = async (ctx) => {
  return {
    props: await loadNamespaces({
      ...ctx,
      pathname: '/login',
    }),
  }
}

function Login() {
  return (
    <MarketingDrawer emptyFooter headerType={'h2'} authPage>
      <MarketingShortTitle hidden />
      <SignOnForm loginView />
    </MarketingDrawer>
  )
}

Login.getLayout = AppLayout

export default metaSetter(
  { Login },
  {
    translation: 'login',
    gql: true,
  }
)
