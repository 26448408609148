import { companyName } from '@app/configs'
import React from 'react'

// TODO: translations
export const MarketingShortTitle = ({ hidden }: { hidden?: boolean }) => {
  return (
    <div
      className={`hidden md:block py-10 text-center${hidden ? ' sr-only' : ''}`}
    >
      <div className={'font-bold text-2xl'}>{companyName}</div>
      {/* <div className={'text-lg'}>Elevating accessibility for every website</div> */}
    </div>
  )
}
