// determine what queries a page can run initially via the route path
export const buildScopeQuery = (pathname: string, query: boolean) => {
  let scopedQuery = ''
  let initialQuery = query

  if (pathname.startsWith('/dashboard')) {
    scopedQuery = 'websites'
  }

  return {
    initialQuery,
    scopedQuery,
  }
}
