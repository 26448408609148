import React from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { LOGGIN_ROUTES, companyName } from '@app/configs'
import { RestWebsiteProviderWrapper } from '@app/components/providers/rest/rest-website'
import type { InnerApp } from '@app/types/page'
import { buildScopeQuery } from '@app/utils/build-scope'
import { InteractiveProvider } from './providers/interactive'
import { AuthProvider } from './providers/auth'
import { WebsiteProviderWrapper } from './providers/website'
import { WASMContextProvider } from './providers/wasm'
import { ThemeProvider } from './themes/theme'
import { LayoutHead } from './meta/layout-head'
import { Analytics } from '@vercel/analytics/react'

// load the application with providers depending on component
const LayoutWrapper = ({ Component, pageProps, pathName }: InnerApp) => {
  const { wasm, gql, rest } = Component
  const { initialQuery, scopedQuery } = buildScopeQuery(
    pathName,
    LOGGIN_ROUTES.includes(pathName)
  )

  return (
    <ThemeProvider attribute='class' enableSystem>
      <WASMContextProvider load={wasm}>
        <AuthProvider
          load={
            wasm ||
            gql ||
            rest ||
            [
              '/api-info',
              '/dashboard',
              '/dashboard/tokens',
              'payments/complete',
            ].includes(pathName)
          }
        >
          <InteractiveProvider load={wasm || gql} demo={pathName === '/demo'}>
            <WebsiteProviderWrapper
              skip={!initialQuery}
              gqlFilter={Component?.params?.filter}
              scopedQuery={scopedQuery}
              gql={gql}
            >
              <RestWebsiteProviderWrapper rest={rest}>
                <Component {...pageProps} />
              </RestWebsiteProviderWrapper>
            </WebsiteProviderWrapper>
          </InteractiveProvider>
        </AuthProvider>
      </WASMContextProvider>
    </ThemeProvider>
  )
}

// App wrapping the main marketing pages and application
export function AppLayout({ Component, pageProps }: InnerApp) {
  const { description, title, translation } = Component.meta ?? {}
  const { t, lang } = useTranslation(translation)
  const router = useRouter()

  const pathName = router.pathname

  const metaTitle = translation
    ? t(`${translation}:title`)
    : title || `${companyName}: ${t('common:web-accessibility')}`
  const metaDescription = translation
    ? t(`${translation}:description`)
    : description

  return (
    <>
      <LayoutHead
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        pathName={pathName}
        lang={lang}
      />
      <LayoutWrapper
        Component={Component}
        pageProps={pageProps}
        pathName={pathName}
      />
      {process.env.NEXT_PUBLIC_ANALYTICS ? <Analytics /> : null}
    </>
  )
}
